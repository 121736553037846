import React from 'react';
import './plasticWindow.scss';
import SideMenu from '../../sideMenu/SideMenu';
import FindPrice from '../../findPrice/FindPrice';

import windowTitle from '../../../images/plastic/title_window.jpeg';
import rehauGrazio from '../../../images/plastic/rehau_grazio.png';
import rehauDelight from '../../../images/plastic/rehau_delight.png';
import color1 from '../../../images/plastic/1.jpg';
import color2 from '../../../images/plastic/2.jpg';
import color3 from '../../../images/plastic/3.jpg';
import color4 from '../../../images/plastic/4.jpg';
import color5 from '../../../images/plastic/5.jpg';
import color6 from '../../../images/plastic/6.jpg';
import color7 from '../../../images/plastic/7.jpg';
import color8 from '../../../images/plastic/8.jpg';
import color9 from '../../../images/plastic/9.jpg';
import color10 from '../../../images/plastic/10.jpg';
import color11 from '../../../images/plastic/11.jpg';
import color12 from '../../../images/plastic/12.jpg';


export default function PlasticWindow() { 
    return( 
        <section className='plasticWindow'>

            <div className='container'>
                <div className='menu_content'>
                    <SideMenu/>

                    <div className='button_content'>
                        <h3>ПВХ окна</h3>
                        <img src={windowTitle} alt='ПВХ окна'/>
                        <p>Сейчас на рынке представлено большое количество разных производителей, которые предлагают еще большее количество вариантов исполнения пластиковых окон. Однако, мы пошли иным путем: многолетний опыт работы в этой сфере позволил нам выделить несколько из наилучших на наш взгляд вариантов <span>по соотношению цена/качество.</span></p>
                        <p>В сегменте ПВХ окон мы выделяем REHAU GRAZIO и REHAU DELIGHT. Это прекрасные варианты, которые закрывают требования к пластиковым окнам по <span>шумо- теплоизоляции, энергосбережению, защите от сквозняков, светопрозрачности, удобству и безопасности в эксплуатации</span> в 99% случаев. И все это за разумную цену.</p>
                        <p>Таким образом, Вам не нужно вникать во все существующие варианты, можно выбрать любой из представленных ниже - и Вы не ошибетесь!</p>
                        
                        <h3>REHAU GRAZIO:</h3> 
                        <img src={rehauGrazio} alt='rehau grazio'/>
                        <p>Система оконных профилей REHAU GRAZIO — сравнительно новый продукт с отличными потребительскими характеристиками. Благодаря монтажной глубине 70 мм и <span>5 внутренним камерам</span> система обладает очень хорошей <span>теплосберегающей способностью</span> и позволяет устанавливать энергоэффективный двухкамерный стеклопакет толщиной до 40 мм. Такие окна могут эксплуатироваться даже в суровом северном климате со средней зимней температурой ниже -30°С.</p> 
                        <p>Кроме того, эта система отличается повышенной <span>светопропускной способностью</span> за счет более узкого профиля (видимая ширина пары коробка+створка всего 104 мм, против 120мм стандартных окон. Однако, при желании можно изготовить и "классический" вариант размера профиля в 120мм), что особо значимо для узких оконных проемов.</p>
                        <p>Система REHAU GRAZIO с повышенным коэффициентом сопротивления теплопередаче (0,85 м²·ºС/Вт), дополненная энергосберегающим стеклопакетом толщиной до 40 мм, позволит <span>сэкономить на отоплении до 30%.</span></p>
                        <p>Высокая герметичность притвора окон на базе системы GRAZIO: два контура уплотнения из оригинального уплотнения REHAU RAU-TUBE надежно защищают от сквозняков и протечек.</p>
                        
                        <h4>Технические характеристики</h4>
                        <ul>
                            <li>Системная глубина/число камер: 70 мм/ 5.</li>
                            <li>Сопротивление теплопередачи профиля (Rопр) — 0,85 м2·ºС/Вт.</li>
                            <li>Число контуров уплотнения — 2 (нахлёст уплотнений 5/8 мм внутри). Такая конструкция обеспечивает надежную <span>защиту от сквозняков, пыли и протечек при дожде с сильным ветром.</span></li>
                            <li>Возможность установки усиленных приборов запирания благодаря смещению оси приборного паза 13 мм. Такое решение увеличивает <span>устойчивость окна к взлому.</span></li>
                            <li>Поверхность профиля — идеально гладкая, удобная для ухода</li>
                            <li>Возможная толщина стеклопакета  —  до 40 мм</li>
                            <li><span>Изоляция воздушного шума</span> (Rw) — от 39 до 45 дБ в зависимости от толщины и типа стёкол в стеклопакетах.</li>
                        </ul>

                        <h3>REHAU DELIGHT:</h3>
                        <img src={rehauDelight} alt='rehau delight'/>
                        <p>Техническое решение DELIGHT-Design дает возможность сократить высоту профильной конструкции и впустить в помещение <span>на 10% больше света</span> по сравнению с обычными окнами. А это – больше хорошего самочувствия!</p>
                        <p><span>Системная глубина 70 мм и пять внутренних камер</span> позволяют добиться высоких значений сопротивления теплопередаче: такие профили не промерзнут даже суровой зимой.</p>
                        <p>Удобство этого профиля заключается в том, что он позволяет адаптировать технические характеристики и дизайн готовых изделий под любые запросы за счет ширины фальца. У DELIGHT-Design <span>ширина фальца составляет 54 мм</span>, то есть в створки можно установить двухкамерные стеклопакеты толщиной до 41 мм – энергосберегающие (оптимальный выбор для частного дома) или акустические (лучший вариант для квартиры, выходящей на шумную улицу).</p>
                        <p>Этот профиль позволяет, при желании, дополнить конструкцию <span>противовзломной фурнитурой, усиленным армированием профилей и антивандальными стеклопакетами со специальным противовзломным стеклом-триплексом.</span></p>

                        <h4>Технические характеристики</h4>
                        <ul>
                            <li>Видимая ширина комбинации коробка+створка — 105/109 мм.</li>
                            <li>Сопротивление теплопередаче профиля (Rо пр.) — 0,94 м²°С/Вт.</li>
                            <li>Число контуров уплотнения — два; нахлест уплотнений: внутри 8 мм, снаружи 7 мм.</li>
                            <li><span>Повышенная взломобезопасность</span> — благодаря смещению оси приборного паза на 13 мм.</li>
                            <li>Поверхность профиля высококачественная, идеально гладкая, стойкая к образованию царапин.</li>
                            <li>Возможная <span>толщина стеклопакета</span> или иного заполнения — до 40 мм</li>
                            <li><span>Изоляция воздушного шума</span> (Rw) — от 39 до 45 дБ в зависимости от толщины и типа стеклопакета.</li>
                        </ul>

                        <h3>Виды ламинации окон</h3>
                        <div className='colors'>
                            <img src={color1} alt='color'/>
                            <img src={color2} alt='color'/>
                            <img src={color3} alt='color'/>
                            <img src={color4} alt='color'/>
                            <img src={color5} alt='color'/>
                            <img src={color6} alt='color'/>
                            <img src={color7} alt='color'/>
                            <img src={color8} alt='color'/>
                            <img src={color9} alt='color'/>
                            <img src={color10} alt='color'/>
                            <img src={color11} alt='color'/>
                            <img src={color12} alt='color'/>
                        </div>
                        <p>Всегда к окнам можно заказать <span>дополнительные аксессуары</span>: детский замок, противовзломная фурнитура, теплые откосы и т.п.</p>
                        
                        <FindPrice/>

                    </div>
                </div>
            </div>
        </section>
    )
};