import React from 'react';
import './plasticWall.scss';
import SideMenu from '../../sideMenu/SideMenu';
import FindPrice from '../../findPrice/FindPrice';

import wallTittle1 from '../../../images/plastic/walltittle.jpeg';
import wallTittle2 from '../../../images/plastic/walltittle2.jpeg';
import wallTittle3 from '../../../images/plastic/walltittle3.jpeg';
import color1 from '../../../images/plastic/lamidoor1.jpg';
import color2 from '../../../images/plastic/lamidoor2.jpg';
import color3 from '../../../images/plastic/lamidoor3.jpg';
import color4 from '../../../images/plastic/lamidoor4.jpg';
import rehau_60 from '../../../images/plastic/rehau_60.png';
import rehau_70 from '../../../images/plastic/rehau_70.png';


export default function PlasticWall() { 
    return( 
        <section className='plasticWall'>

            <div className='container'>
                <div className='menu_content'>
                    <SideMenu/>

                    <div className='button_content'>
                        <h3>ПВХ перегородки</h3>
                        <div className='wallTittle'>
                        <img src={wallTittle1} alt='ПВХ перегородки'/>
                        <img src={wallTittle2} alt='ПВХ перегородки'/>
                        <img src={wallTittle3} alt='ПВХ перегородки'/>
                        </div>
                        <p>ПВХ перегородки - это устоявшееся решение для офисных помещений, если необходимо произвести <span>зонирование</span> и разделить единое пространство на несколько рабочих, выделить общие зоны отдыха, переговорные, секретариат и т.п.</p>
                        <p>Подобные перегородки позволяют максимально рационально использовать арендуемую площадь. Кроме того, современное исполнение ПВХ перегородок выглядит крайне привлекательно и может служить самостоятельным дизайнерским элементом.</p>
                        <p>Помимо офисных помещений ПВХ перегородки также в целях зонирования могут смело использоваться <span>в квартирах и домах.</span></p>
                        <p>Сейчас на рынке представлено большое количество разных производителей, которые предлагают еще большее количество вариантов исполнения пвх перегородок. Однако, мы пошли иным путем: многолетний опыт работы в этой сфере позволил нам выделить несколько из наилучших на наш взгляд вариантов <span>по соотношению цена/качество</span>.</p>
                        <p>В сегменте ПВХ перегородок мы остановили свой выбор на профилях REHAU 60мм и 70мм. Это те варианты, которые обеспечивают нужный уровень <span>шумо- теплоизоляции, энергосбережения и защиты от сквозняков</span> по разумной цене.</p>
                        <p>Таким образом, Вам не нужно вникать во все существующие варианты, можно выбрать любой из представленных ниже - и Вы не ошибетесь!</p>

                        <h3>REHAU профиль 60 мм</h3>
                        <div><img src={rehau_60} alt='wall60'/></div>
                        <p>Сочетает в себе отличные показатели по шумо- и теплоизоляции, высокое качество профиля и элегантный дизайн.</p>
                        
                        <h4>Основные системные характеристики:</h4>
                        <ul>
                        <li>Системная глубина: 60 мм.</li>
                        <li>Количество камер: 2 (створка) / 4 (коробка).</li>
                        <li>Максимальная толщина заполнения: 33 мм.</li>
                        <li>Поверхность: гладкая и легкая в уходе.</li>
                        <li>Взломобезопасность: до 3 класса.</li>
                        <li>Звукоизоляция: до 4 класса.</li>
                        </ul>

                        <h3>REHAU профиль 70 мм</h3>
                        <div><img src={rehau_70} alt='wall70'/></div>
                        <p>4 воздушные камеры и 2 контура уплотнений, выполненных из качественного синтетического каучука препятствуют проникновению холодного воздуха.</p>
                        
                        <h4>Основные системные характеристики:</h4>
                        <ul>
                        <li>Системная глубина: 70 мм.</li>
                        <li>Количество камер: 4/5</li>
                        <li>Приведенное сопротивление теплопередаче Rопр 0,70 м²°С/Вт со стальным армированием</li>
                        <li>Материал: RAU-PVC, с использованием кальций-цинковых стабилизаторов</li>
                        <li>Улучшенная теплотехника за счет 4-х камерного строения профиля</li>
                        <li>Совместимость с коробками системной глубины 70 мм и типовое армирование</li>
                        </ul>
                        <h3>Варианты ламинации</h3>
                        <div className='colors'>
                        <img src={color1} alt='color'/>
                        <img src={color2} alt='color'/>
                        <img src={color3} alt='color'/>
                        <img src={color4} alt='color'/>
                    </div>

                       <FindPrice/>
                    </div>
                </div>
            </div>
        </section>
    )
};