import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import './automation.scss';
import SideMenu from '../../components/sideMenu/SideMenu';

import titleAvtomatic from '../../images/avtomation/title.png';
import ambo from '../../images/avtomation/ambo.png';
import levigato from '../../images/avtomation/levigato.png';
import roteo from '../../images/avtomation/roteo.png';
import scopio from '../../images/avtomation/scopio.png';
import targo from '../../images/avtomation/targo.png';
import asw from '../../images/avtomation/asw.png';
import garazh from '../../images/garazh_vorota.jpg';

import niceShel from '../../images/avtomation/niceshel.png';
import niceSpin from '../../images/avtomation/nicespin.jpeg';

import sectional800 from '../../images/doorhan/sectional800.jpg';
import sectional1200 from '../../images/doorhan/sectional1200.jpg';
import shaft from '../../images/doorhan/shaft.jpg';
import sliding from '../../images/doorhan/sliding.jpg';
import swing from '../../images/doorhan/swing.jpg';
import swingPro from '../../images/doorhan/swingpro.jpg';
import arm from '../../images/doorhan/arm.jpg';
import gsm from '../../images/doorhan/gsm.jpg';
import wifiModule from '../../images/doorhan/Wi-Fi.jpg';
import dhre from '../../images/doorhan/dhre.jpg';
import lampa from '../../images/doorhan/lampa.jpg';
import rolletPhoto from '../../images/doorhan/rolletPhoto.jpg';
import rolletTransmitter from '../../images/doorhan/rolletTransmitter.jpg';
import transmitter4 from '../../images/doorhan/Transmitter4.jpg';
import keySafe from '../../images/doorhan/keySafe.jpg';
import lock from '../../images/doorhan/lock.jpg';
import lock2 from '../../images/doorhan/lock2.jpg';
import domophone from '../../images/doorhan/domofon.jpg';
import photocell2 from '../../images/doorhan/photocell2.jpg';
import codeKey from '../../images/doorhan/codeKey.jpg';
import trafficLight from '../../images/doorhan/trafficLight.jpg';
import antenna from '../../images/doorhan/antenna.jpg';
import keyPad from '../../images/doorhan/keyPad.jpg';
import fingerprint from '../../images/doorhan/fingerprint.jpg';

import hopp from '../../images/avtomation/hopp7024.png';
import wg from '../../images/avtomation/wg4024.png';
import wl from '../../images/avtomation/wl1024.png';
import hyke from '../../images/avtomation/hyke-7224.jpg';
import hyppo from '../../images/avtomation/hyppo7005.jpg';
import fab from '../../images/avtomation/m-fab-3010.jpg';
import to from '../../images/avtomation/to4024.jpg';
import too from '../../images/avtomation/too3000.jpg';



export default function Automation() {

    const [activeTab, setActiveTab] = useState(1);
    
    return( 
        <section className='automation'>
            <div className='container'>

                <div className='menu_content'>
                    <SideMenu/>

                    <div className='button_content'>
                    <h5>ВЫБЕРИТЕ БРЕНД:</h5>
                        <div className='buttonWrapper'>
                            <button autoFocus={true} onSelectCapture={true}  onClick={() => setActiveTab(1)}>alutech</button>
                            <button onClick={() => setActiveTab(2)}>nice</button>
                            <button onClick={() => setActiveTab(3)}>doorhan</button>
                            <button onClick={() => setActiveTab(4)}>hoermann</button>
                        </div>
                        {activeTab === 1 && 
                            <div className='content'>
                            <h3>автоматика alutech</h3>
                                <img src={titleAvtomatic} alt='titleAvtomatic'/>
                                <h2>Автоматика для гаражных ворот АЛЮТЕХ</h2>
                                <div className='speedLevigato'>
                                    <div>
                                        <img src={levigato} alt='скоростной привод levigato'/>
                                        <p>Комплект поставки: привод, монтажный набор, 2 пульта, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div>
                                        <h3>Скоростной привод для гаражных ворот Levigato LG-600F</h3>
                                        <p>Мощность 280 Вт, тяговое усилие 600Н, Smax = 8,4 м², скорость 0,2 м/с, интенсивность 18 ц/час, IP20</p>
                                    </div>
                                    <div>
                                        <h3>Скоростной привод для гаражных ворот Levigato LG-1000F</h3>
                                        <p>Мощность 400 Вт, тяговое усилие 1000Н, Smax = 16,2 м², скорость 0,2 м/с, интенсивность 18 ц/час, IP20</p>
                                    </div>
                                </div>
                                <div className='levigato'>
                                    <div>
                                        <img src={levigato} alt='привод levigato'/>
                                        <p>Комплект поставки: привод, монтажный набор, 2 пульта, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div>
                                        <h3>Привод для гаражных ворот Levigato LG-800</h3>
                                        <p>Мощность 220 Вт, тяговое усилие 800Н, Smax = 11,2 м², скорость 0,15 м/с, интенсивность 18 ц/час, IP20</p>
                                    </div>
                                    <div>
                                        <h3>Привод для гаражных ворот Levigato LG-1200</h3>
                                        <p>Мощность 330 Вт, тяговое усилие 1200Н, Smax = 18,6 м², скорость 0,15 м/с, интенсивность 18 ц/час, IP20</p>
                                    </div>
                                    <div>
                                        <h3>Привод для гаражных ворот Levigato LG-500</h3>
                                        <p>Мощность 150 Вт, тяговое усилие 500Н, Smax = 8,4 м², скорость 0,14 м/с, интенсивность 10 ц/час, IP20</p>
                                    </div>
                                </div>

                                <h2>Автоматика для промышленных ворот АЛЮТЕХ</h2>
                                <div className='targo'>
                                    <div>
                                        <img src={targo} alt='привод targo'/>
                                        <p>Комплект поставки: привод, блок управления, монтажный набор, 2 пульта, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div>
                                        <h3>Привод для промышленных ворот TARGO TR-3531-230KIT</h3>
                                        <p>Двигатель 230В, мощность 750 Вт, крутящий момент 35Н, частота вращения 31 об/мин, Smax = 12 м², интенсивность 25%, IP65</p>
                                    </div>
                                    <div>
                                        <h3>Привод для промышленных ворот TARGO TR-5024-230KIT</h3>
                                        <p>Двигатель 230В, мощность 670 Вт, крутящий момент 50Н, частота 24 об/мин, Smax = 18 м², интенсивность 25%, IP65</p>
                                    </div>
                                    <div>
                                        <h3>Привод для промышленных ворот TARGO TR-5024-400KIT</h3>
                                        <p>Двигатель 400В, мощность 610 Вт, крутящий момент 50Н, частота 24 об/мин, Smax = 18 м², интенсивность 60%, IP65</p>
                                    </div>
                                    <div>
                                        <h3>Привод для промышленных ворот TARGO TR-10024-400KIT</h3>
                                        <p>Двигатель 400В, мощность 1000 Вт, крутящий момент 100Н, частота 24 об/мин, Smax = 30 м², интенсивность 60%, IP65</p>
                                    </div>
                                    <div>
                                        <h3>Привод для промышленных ворот TARGO TR-13018-400KIT</h3>
                                        <p>Двигатель 400В, мощность 1000 Вт, крутящий момент 130Н, частота 18 об/мин, Smax = 42 м², интенсивность 60%, IP65</p>
                                    </div>
                                </div>

                                <h2>Автоматика для откатных ворот АЛЮТЕХ</h2>
                                <div className='roteo'>
                                    <div>
                                        <img src={roteo} alt='привод roteo'/>
                                        <p>Комплект поставки: привод, монтажный набор, 2 пульта, 2 ключа разблокировки, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div>
                                        <h3>Привод для откатных ворот ROTEO RTО-500</h3>
                                        <p>Двигатель 230В, мощность 500 Вт, тяговое усилие 500Н, mmax = 500 кг, скорость 0,2 м/с, интенсивность 25%, IP44</p>
                                    </div>
                                    <div>
                                        <h3>Привод для откатных ворот ROTEO RTО-1000</h3>
                                        <p>Двигатель 230В, мощность 700 Вт, тяговое усилие 700Н, mmax = 1000 кг, скорость 0,2 м/с, интенсивность 25%, IP44</p>
                                    </div>
                                    <div>
                                        <h3>Привод для откатных ворот ROTEO RTО-2000</h3>
                                        <p>Двигатель 230В, мощность 1000 Вт, тяговое усилие 1100Н, mmax = 2000 кг, скорость 0,2 м/с, интенсивность 50%, IP44</p>
                                    </div>
                                </div>

                                <h2>Автоматика для распашных ворот АЛЮТЕХ</h2>
                                <div className='scopio'>
                                    <div>
                                        <img src={scopio} alt='привод scopio'/>
                                        <p>Комплект поставки: 2 привода, блок управления, монтажный набор, ключ разблокировки, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div>
                                        <h3>Телескопический привод для распашных ворот SCOPIO SC‑3000SKIT</h3>
                                        <p>Двигатель 230В, мощность 150 Вт, тяговое усилие 3000Н, mmax = 350 кг, скорость 0,16 м/с, интенсивность 25%, IP54</p>
                                    </div>
                                    <div>    
                                        <h3>Телескопический привод для распашных ворот SCOPIO SC‑3000SKIT-N</h3>
                                        <p>Двигатель 230В, мощность 150 Вт, тяговое усилие 3000Н, mmax = 350 кг, скорость 0,16 м/с, интенсивность 25%, IP54</p>
                                    </div>
                                </div>
                                <div className='ambo'>
                                    <div>
                                        <img src={ambo} alt='привод ambo'/>
                                        <p>Комплект поставки: 2 привода, блок управления, монтажный набор, ключ разблокировки, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div>     
                                        <h3>Комплект приводов для распашных ворот ALUTECH AMBO AM5000KIT</h3>
                                        <p>Двигатель 230В, мощность 150 Вт, тяговое усилие 3000Н, mmax = 500 кг, скорость 0,16 м/с, интенсивность 25%, IP54</p>
                                    </div>
                                </div>
                                <div className='asw'>
                                    <div>
                                        <img src={asw} alt='привод asw'/>
                                        <p>Комплект поставки: 2 привода, блок управления, монтажный набор, руководство по монтажу и эксплуатации</p>
                                    </div>
                                    <div> 
                                        <h3>Комплект приводов для распашных ворот AN-Motors ASW4000KIT</h3>
                                        <p>Двигатель 230В, мощность 120 Вт, тяговое усилие 320Н, mmax = 400 кг, скорость 0,2 м/с, интенсивность 25%, IP54</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeTab === 2 && 
                            <div className='content'>
                            <h3>автоматика nice</h3>
                            <h2>Автоматика для гаражных ворот НАЙС</h2>
                                <div className='shel'>
                                    <img src={niceShel} alt='shel75'/>
                                    <div>
                                    <h3>Электромеханический привод SHEL75</h3>
                                    <p>Электромеханический привод серии SHEL для автоматизации гаражных секционных ворот площадью до 9,6 м2 с самоблокирующейся приводной рейкой.</p>
                                    <p>Простая установка, надежность в эксплуатации. Подходит для использования на воротах с низкой интенсивностью использования.</p>
                                    <p>Максимальная высота ворот 2.4 м может быть увеличена до 3.4 м с использованием аксессуара SH1.</p>
                                    </div>
                                </div>

                            <div className='plus'>
                                
                                <div className='longPlus'>
                                    <h4>Функциональный для пользователя:</h4>
                                    <ul>
                                        <li>Встроенная лампа подсветки помещения с возможностью дистанционного включения;</li>
                                        <li>Режим неполного открытия створки ворот (режим проветривания помещения);</li>
                                        <li>Функция обнаружения препятствий;</li>
                                        <li>Функция автоматического закрытия ворот.</li>
                                    </ul>
                                </div>
                                <i className="fas fa-hand-point-left"></i>
                                <i className="fas fa-hand-point-right"></i>
                                <div className='longPlus'>
                                    <h4>Надежный при эксплуатации</h4>
                                    <ul>
                                        <li>Двигатель 24В с системой электронных концевых выключателей;</li>
                                        <li>Цепная передача в направляющей рейке;</li>
                                        <li>Возможность разблокировки привода для открытия ворот при отсутствии напряжения в сети.</li>
                                    </ul>
                                </div>
                               
                            </div>

                            <h3>Параметры</h3>
                            <div className='parametersShell'>
                            <p>Питание</p> <p>230/24 В</p>
                            <p>Потребление </p> <p>1.3 А</p>
                            <p>Мощность </p> <p>280 В</p>
                            <p>Крутящий момент </p> <p>7,5 Нм</p>
                            <p>Класс защиты </p> <p>40 IP</p>
                            <p>Максимальная скорость</p> <p> 0,14 м/с</p>
                            <p>Максимальная площадь подъемно-поворотных ворот </p> <p>11,2 м2</p>
                            <p>Максимальная площадь секционных ворот</p> <p> 9,6 м2</p>
                            <p>Максимальная высота секционных ворот</p> <p> 2,4 м</p>
                            <p>Максимальная высота подъемно-поворотных ворот </p> <p>2,8 м</p>
                            <p>Усилие </p> <p>750 Н</p>
                            <p>Температура </p> <p>-20-+50 °C</p>
                            <p>Интенсивность работы </p> <p> 96 циклов/сутки</p>
                            <p>Размеры</p> <p> 305x109x130 мм</p>
                            <p>Вес привода </p> <p>4 кг</p>
                            </div>
      
                            <div className='SPIN22BDKCE'>
                                <img src={niceSpin} alt='SPIN22BDKCE'/>
                                <div>
                                <h3>Электромеханический привод SPIN22BDKCE </h3>
                                <p>Комплект для автоматизации секционных ворот с ременной передачей.</p>
                                <p>Рекомендуется для гаражных секционных ворот площадью до 10,5 м2 и высотой до 3,4 м.</p>
                                <p>Направляющая рейка с ремнем обеспечивает тихую и надежную работу.</p>
                                </div>
                            </div>
                            <i className="fas fa-hand-point-down"></i>
                            <div className='longPlus'>
                                <h4>Преимущества модели Spin22:</h4>
                                <ul>
                                    <li>усилие 650N</li>
                                    <li>встроенный блок управления с технологией BLUEBUS, позволяющей подключить дополнительные устройства всего при помощи двух проводов без соблюдения полярности</li>
                                    <li>амперометрическая система обнаружения препятствий используется для обнаружения возможных препятствий, которые могут возникнуть во время нормального движения ворот. С помощью этой функции, в случае наличия препятствия, привод остановит створку и изменит направление на обратное</li>
                                    <li>электронная регулировка скорости — настраиваемое усорение и замедление, регулируемые в начале и конце маневра</li>
                                    <li>при неиспользовании системы, привод переходит в режим ожидания, что позволяет снизить потребление электроэнергии</li>
                                    <li>режим самообучения, во время которого привод автоматически запоминает крайние положения, и сохраняет в памяти эти значения при каждом маневре</li>
                                    <li>функция частичного открывания, благодаря которому ворота можно открыть на высоту 15см для проветривания, или на 1м, чтобы войти в гараж, не дожидаясь полного открытия ворот</li>
                                    <li>возможность подключения фотоэлементов или чувствительных крав последнего поколения для повышения уровня безопасности при использовании системы</li>
                                    <li>встреонная в блок управления лампа, которая может использоваться для дополнительного освещения гаража</li>
                                    <li>возможность подключения сигнальных ламп типа: WLT</li>
                                </ul>
                            </div>
                            <h3>Параметры</h3>
                            <div className='parameters'>
                            <p>Крутящий момент</p> <p> 5,85 Нм</p>
                            <p>Класс защиты</p> <p> 40 IP</p>
                            <p>Линейная скорость</p> <p> 0,08 м/мин</p>
                            <p>Максимальная площадь подъемно-поворотных ворот</p> <p> 11,76 м2</p>
                            <p>Максимальная площадь секционных ворот</p> <p> 10,56 м2</p>
                            <p>Максимальная высота секционных ворот</p> <p> 2,4 м</p>
                            <p>Максимальная высота подъемно-поворотных ворот</p> <p> 2,8м</p>
                            <p>Усилие</p> <p> 650 Н</p>
                            <p>Температура</p> <p> -20 - +50 °C</p>
                            <p>Интенсивность работы</p> <p> 50 циклов/сутки</p>
                            <p>Размеры</p> <p> 311x327x105 мм</p>
                            <p>Вес привода</p> <p> 3,6 кг</p>
                            </div>

                            <div className='SPIN23BDKCE'>
                                <img src={niceSpin} alt='SPIN23BDKCE'/>
                                <div>
                                <h3>Электромеханический привод SPIN23BDKCE</h3>
                                <p>Электромеханический привод серии SPIN23 - комплект для автоматизации секционных ворот с ременной передачей. </p>
                                <p>Рекомендуется для гаражных секционных ворот площадью до 10,5 м2 и высотой до 2,4 м.</p>
                                <p>Направляющая рейка с ремнем обеспечивает тихую и надежную работу.</p>
                                </div>
                            </div>

                            <i className="fas fa-hand-point-down"></i>
                            <div className='longPlus'>
                                <h4>Преимущества модели Spin22:</h4>
                                <ul>
                                <li>усилие 650N</li>
                                <li>встроенный блок управления с технологией BLUEBUS, позволяющей подключить дополнительные устройства всего при помощи двух проводов без соблюдения полярности</li>
                                <li>блок управления поддерживает технологии Opera и Solemyo</li>
                                <li>амперометрическая система обнаружения препятствий используется для обнаружения возможных препятствий, которые могут возникнуть во время нормального движения ворот. С помощью этой функции, в случае наличия препятствия, привод остановит створку и изменит направление на обратное</li>
                                <li>электронная регулировка скорости — настраиваемое усорение и замедление, регулируемые в начале и конце маневра</li>
                                <li>при неиспользовании системы, привод переходит в режим ожидания, что позволяет снизить потребление электроэнергии</li>
                                <li>режим самообучения, во время которого привод автоматически запоминает крайние положения, и сохраняет в памяти эти значения при каждом маневре</li>
                                <li>функция частичного открывания, благодаря которому ворота можно открыть на высоту 15см для проветривания, или на 1м, чтобы войти в гараж, не дожидаясь полного открытия ворот</li>
                                <li>возможность подклчения фотоэлементов или чувствительных крав последнего поколения для повышения уровня безопасности при использовании системы</li>
                                <li>встреонная в блок управления лампа, которая может использоваться для дополнительного освещения гаража</li>
                                <li>возможность подключения сигнальных ламп типа: WLT</li>
                                </ul>
                            </div>

                            <h3>Параметры</h3>
                            <div className='parameters'>
                            <p>Крутящий момент</p> <p> 5,85 Нм</p>
                            <p>Класс защиты</p> <p> 40 IP</p>
                            <p>Линейная скорость</p> <p> 0,08 м/мин</p>
                            <p>Максимальная площадь подъемно-поворотных ворот</p> <p> 11,76 м2</p>
                            <p>Максимальная площадь секционных ворот</p> <p> 10,56 м2</p>
                            <p>Максимальная высота секционных ворот</p> <p> 2,4 м</p>
                            <p>Максимальная высота подъемно-поворотных ворот</p> <p> 2,8 м</p>
                            <p>Усилие</p> <p> 650 Н</p>
                            <p>Температура</p> <p> -20 - +50 °C</p>
                            <p>Интенсивность работы</p> <p> 50 циклов/сутки</p>
                            <p>Размеры</p> <p> 311x327x105 мм</p>
                            <p>Вес привода</p> <p> 3,6 кг</p>
                            </div>

                            <div className='SN6041'>
                                <img src={niceSpin} alt='SN6041'/>
                                <div>
                                <h3>Электромеханический привод SN6041</h3>
                                <p>Электромеханический привод серии SPIN для автоматизации секционных ворот площадью до 17.5м2 с самоблокирующейся приводной рейкой.</p>
                                <p>Высокотехнологичный привод позволяющий решить широкий спектр задач по автоматизации подъемно-секционных ворот.</p>
                                <p>Тихая работа привода благодаря использованию армированного ремня</p>
                                <p>Встроенная сигнальная лампа, которая может использоваться как для освещения помещения, так и для оповещения о возможных неполадках в системе</p>
                                <p>Возможность управления приводом посредством встроенного выключателя со шнуром</p>
                                <p>Режим энергосбережения</p>
                                <p>Плавный старт и остановка</p>
                                <p>Резервное питание от аккумуляторной батареи PS124, устанавливаемой внутри корпуса привода (опция, только для SPIN23KCE, SN6031, SN6041)</p>
                                <p>Широкие возможности для подключения элементов безопасности</p>
                                <p>Функция автоматического закрытия</p>
                                <p>Режим неполного открытия створки ворот (режим проветривания помещения).</p>
                                </div>
                            </div>

                            <h2>Автоматика для распашных ворот НАЙС</h2>
                            <h4>1)ЛИНЕЙНОГО ТИПА:</h4>
                            <div className='doubleLeafNice'>
                                <img src={too} alt='распашная автоматика'/>
                                <div>
                                    <h2>Серия TOO3000</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p>230 Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>- Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>1,1 А</p>
                                        <p>Мощность</p> <p>250 Ватт</p>
                                        <p>Максимальная скорость</p> <p>0,016 метров в секунду</p>
                                        <p>Усилие</p> <p>300 Ньютон</p>
                                        <p>Интенсивность</p> <p>50 %</p>
                                        <p>Класc защиты</p> <p>54 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>700х100х177 мм</p>
                                        <p>Вес</p> <p>5,5 кг</p>
                                    </div>
                                </div>
                            </div>
                            <div className='doubleLeafNice'>
                                <img src={wg} alt='распашная автоматика'/>
                                <div>
                                    <h2>Серия WINGO: модели WG4024, 5024, 4000, 5000, 3524HS</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> - Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>От 24 до 230 Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>3,5 А</p>
                                        <p>Мощность</p> <p>От 85 до 200 Ватт</p>
                                        <p>Максимальная скорость</p> <p>От 0,04 до 0,018 метров в секунду</p>
                                        <p>Усилие</p> <p>1500 Ньютон</p>
                                        <p>Интенсивность</p> <p>50 %</p>
                                        <p>Класc защиты</p> <p>44 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>От 770х98х95 до 920Х98Х95 мм</p>
                                        <p>Вес</p> <p>6 кг</p>
                                    </div>
                                </div>
                            </div>
                             <div className='doubleLeafNice'>
                                <img src={to} alt='распашная автоматика'/>
                                <div>
                                    <h2>Серия TOONA: модели 4016P, 4024, 5016Р, 5024, 7024, 5024HS, 6024HS</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> От 24 до 230 Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>- Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>1,7 А</p>
                                        <p>Мощность</p> <p>От 120 до 385 Ватт</p>
                                        <p>Максимальная скорость</p> <p>0,013 до 0,025 метров в секунду</p>
                                        <p>Усилие</p> <p>От 1800 до 3200 Ньютон</p>
                                        <p>Интенсивность</p> <p>От 50 до 100 %</p>
                                        <p>Класc защиты</p> <p>44 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>От 820х115х105 до 1200х128х150 мм</p>
                                        <p>Вес</p> <p>От 6 до 15 кг</p>
                                    </div>
                                </div>
                            </div>
                            <h4>2)РЫЧАЖНОГО ТИПА:</h4>
                            <div className='doubleLeafNice'>
                                <img src={wl} alt='распашная автоматика'/>
                                <div>
                                    <h2>серии WALKY: модели 1024, 1024С</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> - Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>24 Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>От 0.8 до 2 А</p>
                                        <p>Мощность</p> <p>от30 до 120 Ватт</p>
                                        <p>Максимальная скорость</p> <p>2,2 0боротов в минуту</p>
                                        <p>Усилие/ крутящий момент</p> <p>120 Ньютон/метр</p>
                                        <p>Интенсивность</p> <p>50 Циклов в час</p>
                                        <p>Класc защиты</p> <p>44 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>90х125х385 мм</p>
                                        <p>Вес</p> <p>4,3 кг</p>
                                    </div>
                                </div>
                            </div>
                            <div className='doubleLeafNice'>
                                <img src={hopp} alt='распашная автоматика'/>
                                <div>
                                    <h2>серия HOPP: модели HO7124, 7224</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> 230 Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>24 Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>1,3 А</p>
                                        <p>Мощность</p> <p>100 Ватт</p>
                                        <p>Максимальная скорость</p> <p>От 100 до 280 0боротов в минуту</p>
                                        <p>Усилие/ крутящий момент</p> <p>250 Ньютон/метр</p>
                                        <p>Интенсивность</p> <p>35 Циклов в час</p>
                                        <p>Класc защиты</p> <p>54 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>252х180х290 мм</p>
                                        <p>Вес</p> <p>От 6.5 до 9 кг</p>
                                    </div>
                                </div>
                            </div>
                            <div className='doubleLeafNice'>
                                <img src={hyppo} alt='распашная автоматика'/>
                                <div>
                                    <h2>серии HYPPO HY7005</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> 230 Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>24 Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>1,2 А</p>
                                        <p>Мощность</p> <p>250 Ватт</p>
                                        <p>Максимальная скорость</p> <p>1,7 0боротов в минуту</p>
                                        <p>Усилие/ крутящий момент</p> <p>400 Ньютон/метр</p>
                                        <p>Интенсивность</p> <p>20 Циклов в час</p>
                                        <p>Класc защиты</p> <p>43 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>350х165х242 мм</p>
                                        <p>Вес</p> <p>13 кг</p>
                                    </div>
                                </div>
                            </div>
                            <div className='doubleLeafNice'>
                                <img src={hyke} alt='распашная автоматика'/>
                                <div>
                                    <h2>серии HYKE: модели HK7024,7224,HK7024HS,HK7224HS</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> - Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>24 Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>От 1.5 до 6 А</p>
                                        <p>Мощность</p> <p>140 до 300 Ватт</p>
                                        <p>Максимальная скорость</p> <p>1,5 0боротов в минуту</p>
                                        <p>Усилие/ крутящий момент</p> <p>500 Ньютон/метр</p>
                                        <p>Интенсивность</p> <p>40 Циклов в час</p>
                                        <p>Класc защиты</p> <p>54 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>290х210х320 мм</p>
                                        <p>Вес</p> <p>9,5 кг</p>
                                    </div>
                                </div>
                            </div>
                            <h4>3)ПОДЗЕМНОГО ТИПА:</h4>
                            <div className='doubleLeafNice'>
                                <img src={fab} alt='распашная автоматика'/>
                                <div>
                                    <h2>Серия M-FAB: модели M-FAB 3010,3024, BIG-FAB 5024</h2>
                                    <h3>Параметры</h3>
                                    <div className='parameters'>
                                        <p>Питание</p> <p> 230 Вольт пер.тока, 50Гц</p>
                                        <p>Питание двигателя</p>  <p>24 Вольт пост. тока</p>
                                        <p>Номинальный ток</p> <p>От 1,4 до 7 А</p>
                                        <p>Мощность</p> <p>От 170 до 290 Ватт</p>
                                        <p>Максимальная скорость</p> <p>От 0,8 до 1,4 0боротов в минуту</p>
                                        <p>Усилие/ крутящий момент</p> <p>От 300 до 400 Ньютон/метр</p>
                                        <p>Интенсивность</p> <p>От 40 до 45 Циклов в час</p>
                                        <p>Класc защиты</p> <p>67 IP</p>
                                        <p>Рабочая температура</p> <p>-20 / +50 °C мин/макс</p>
                                        <p>Размеры</p> <p>от 390х322х194,5 до 400x325x150 мм</p>
                                        <p>Вес</p> <p>12 кг</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {activeTab === 3 && 
                            <div className='content'>
                            <h3>автоматика doorhan</h3>
                                <h2>Автоматика для гаражных ворот ДОРХАН - потолочные приводы:</h2>
                                <div className='sectional'>
                                    <img src={sectional800} alt='привод sectional'/>
                                    <div>
                                        <h3>Потолочный привод SECTIONAL-800PRO и SECTIONAL-800PRO</h3>
                                        <div className='characteristics'>
                                            <p>Максимальный вес полотна ворот, кг</p>
                                            <p>150/180</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240</p>
                                            <p>Режим ожидания, Вт</p>
                                            <p>6</p>
                                            <p>Усилие, Н</p>
                                            <p>800/1000</p>
                                            <p>Электродвигатель</p>
                                            <p>пошaговый (импульсный)</p>
                                            <p>Двигатель, В</p>
                                            <p>24В DC</p>
                                            <p>Скорость открывания, м/мин</p>
                                            <p>6</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-20...+55</p>
                                            <p>Предохранители</p>
                                            <p>питание 2,5 А</p>
                                            <p>Передача движения</p>
                                            <p>цепь/ремень</p>
                                            <p>Класс защиты IP</p>
                                            <p>IP 20</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='sectional1200'>
                                    <img src={sectional1200} alt='привод sectional'/>
                                    <div>
                                        <h3>Потолочный привод SECTIONAL-1200</h3>
                                        <div className='characteristics'>
                                            <p>Максимальный вес полотна ворот, кг</p>
                                            <p>220</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240</p>
                                            <p>Усилие, Н</p>
                                            <p>1200</p>
                                            <p>Двигатель, В</p>
                                            <p>24 DC</p>
                                            <p>Скорость открывания, м/мин</p>
                                            <p>6</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-20...+55</p>
                                            <p>Интенсивность использования, %</p>
                                            <p>50 %</p>
                                            <p>Класс защиты IP</p>
                                            <p>IP 20</p>
                                        </div>
                                    </div>
                                </div>
                                <h2>Автоматика для промышленных ворот ДОРХАН - вальные приводы:</h2>
                                <div className='shaft'>
                                    <img src={shaft} alt='привод shaft'/>
                                    <div>
                                        <h3>Вальный привод SHAFT-20, SHAFT-30-60, SHAFT-50, SHAFT-50PRO, SHAFT-120-200</h3>
                                        <div className='characteristics'>
                                            <p>Максимальный вес полотна ворот, кг</p>
                                            <p>от 160 до 850</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240 / 380-340</p>
                                            <p>Крутящий момент, Нм</p>
                                            <p>от 20 до 60</p>
                                            <p>Мощность, Вт</p>
                                            <p>от 300 до 800</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-40...+55</p>
                                            <p>Скорость вращения мотора, об/мин</p>
                                            <p>1 400</p>
                                            <p>Класс защиты IP</p>
                                            <p>от IP 20 до IP54</p>
                                            <p>Интенсивность использования, %</p>
                                            <p>от 40 до 65</p>   
                                        </div>
                                    </div>
                                </div>
                                <h2>Автоматика для откатных ворот ДОРХАН:</h2>
                                <div className='sliding'>
                                    <img src={sliding} alt='привод sliding'/>
                                    <div>
                                        <h3>Привод SLIDING-500-800, SLIDING-800PRO, SLIDING-1300-2100PRO, SLIDING-5000</h3>
                                        <div className='characteristics'>
                                            <p>Максимальный вес полотна ворот, кг</p>
                                            <p>от 500 до 5000</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240</p>
                                            <p>Максимальное усилие, Н</p>
                                            <p>220–1350</p>
                                            <p>Крутящий момент, Нм</p>
                                            <p>от 16 до 43</p>
                                            <p>Мощность, Вт</p>
                                            <p>от 260 до 1500</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-40...+55</p>
                                            <p>Скорость открывания, м/мин</p>
                                            <p>от 10 до 12</p>
                                            <p>Класс защиты IP</p>
                                            <p>IP54</p>
                                            <p>Интенсивность использования, %</p>
                                            <p>от 50 до 100</p>   
                                        </div>
                                    </div>
                                </div>
                                <h2>Автоматика для распашных ворот ДОРХАН:</h2>
                                <div className='swing'>
                                    <img src={swing} alt='привод swing'/>
                                    <div>
                                        <h3>Линейный привод SWING-4000-BASE и SWING-24</h3>
                                        <div className='characteristics'>
                                            <p>Максимальная длина створки, м</p>
                                            <p>4</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240</p>
                                            <p>Максимальный вес створки, кг</p>
                                            <p>от 200 до 400</p>
                                            <p>Мощность, Вт</p>
                                            <p>от 100 до 140</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-20...+55</p>
                                            <p>Класс защиты IP</p>
                                            <p>от IP44 до IP54</p>
                                            <p>Интенсивность использования, %</p>
                                            <p>от 50 до 100</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='swingPro'>
                                    <img src={swingPro} alt='привод swingPro'/>
                                    <div>
                                        <h3>Линейный привод SWING-3000PRO и SWING-5000PRO</h3>
                                        <div className='characteristics'>
                                            <p>Максимальная длина створки, м</p>
                                            <p>от 3 до 5</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240</p>
                                            <p>Максимальный вес створки, кг</p>
                                            <p>от 400 до 500</p>
                                            <p>Мощность, Вт</p>
                                            <p>150</p>
                                            <p>Скорость открывания, м/мин</p>
                                            <p>0,9</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-40...+55</p>
                                            <p>Класс защиты IP</p>
                                            <p>54</p>
                                            <p>Интенсивность использования, %</p>
                                            <p>от 50</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='arm'>
                                    <img src={arm} alt='привод arm'/>
                                    <div>
                                        <h3>Рычажный привод ARM-230 и ARM-320PRO</h3>
                                        <div className='characteristics'>
                                            <p>Максимальная длина створки, м</p>
                                            <p>от 1,5 до 2</p>
                                            <p>Напряжение питания, В</p>
                                            <p>220–240</p>
                                            <p>Максимальный вес створки, кг</p>
                                            <p>от 300 до 400</p>
                                            <p>Мощность, Вт</p>
                                            <p>от 120 до 300</p>
                                            <p>Диапазон рабочих температур, °С</p>
                                            <p>-40...+55</p>
                                            <p>Скорость открывания, м/мин</p>
                                            <p>0,78</p>
                                            <p>Класс защиты IP</p>
                                            <p>44</p>
                                            <p>Интенсивность использования, %</p>
                                            <p>от 30 до 100</p>
                                        </div>
                                    </div>
                                </div>
                                <h4>ДОПОЛНИТЕЛЬНАЯ КОМПЛЕКТАЦИЯ</h4>
                            <div className='additionWrapper'>
                                <div>
                                    <img src={gsm} alt='GSM-модуль'/>
                                    <p>GSM-модуль 3.0 GSM-модуль предназначен для приема сигнала с мобильного телефона и дистанционной передачи команд (тип контактов NO, NC) для управления шлагбаумами, приводами, системами освещения и другими типами электрооборудования.</p>
                                </div>
                                <div>
                                    <img src={wifiModule} alt='Wi-Fi-модуль'/>
                                    <p>Wi-Fi-модуль Smartcontrol-2 предназначен для беспроводного управления (выработки сигнала управления NO) электроприводами.</p>
                                </div>
                                <div>
                                    <img src={dhre} alt='Внешний радиоприемник DHRE-1'/>
                                    <p>Внешний радиоприемник DHRE-1 для управления автоматикой других производителей с помощью пульта DoorHan</p>
                                </div>
                                <div>
                                    <img src={antenna} alt='АНТЕННА 433 MHZ'/>
                                    <p>Антенна Antena-433 создана для увеличения дальности приема сигнала с пультов дистанционного управления. Кронштейн крепления антенны мобилен и удобен при монтаже.</p>
                                </div>
                                <div>
                                    <img src={rolletPhoto} alt='Фотоэлементы'/>
                                    <p>Фотоэлементы — это устройство безопасности, состоящее из инфракрасного передатчика и приемника, которые устанавливаются в проем ворот. В случае пересечения инфракрасного луча в блок управления поступает сигнал о появлении препятствия в опасной зоне действия автоматической системы, и система вырабатывает реакцию, соответствующую установленной логике работы — остановка или реверс полотна ворот.</p>
                                </div>
                                <div>
                                    <img src={photocell2} alt='Фотоэлементы'/>
                                    <p>Фотоэлементы беспроводные Photocell-PRO — устройство безопасности, состоящее из инфракрасного передатчика и приемника, которые устанавливаются в проем ворот. В случае пересечения инфракрасного луча в блок управления поступает сигнал о появлении препятствия в опасной зоне действия автоматической системы, и система вырабатывает реакцию, соответствующую установленной логике работы — остановка или реверс полотна ворот. Поворотная плата позволяет монтировать фотоэлемент на неровных поверхностях.</p>
                                </div>
                                <div>
                                    <img src={lampa} alt='светодиодная лампа'/>
                                    <p>Сигнальная лампа LAMP PRO – устройство безопасности. Предупреждает о начале движения ворот и работает в течение всего цикла их движения.</p>
                                </div>
                                <div>
                                    <img src={trafficLight} alt='CВЕТОФОР'/>
                                    <p>CВЕТОФОР TRAFFICLIGHT-LED Предназначен для использования с автоматическими устройствами, ограничивающими доступ на стоянках, автомойках, в гаражных комплексах, подземных и многоуровневых паркингах. Применение данного светофора позволяет повысить безопасность движения транспортных средств при въезде/выезде на огороженной территории, а также существенно снизить энергопотребление.</p>
                                </div>
                                <div>
                                    <img src={keyPad} alt='Радиокодовая клавиатура KeyPad'/>
                                    <p>Радиокодовая беспроводная клавиатура KeyPad, совместимая с встроенным и внешним приемниками DoorHan, позволяет дистанционно управлять приводами DoorHan.</p>
                                </div>
                                <div>
                                    <img src={codeKey} alt='АНТИВАНДАЛЬНАЯ КОДОВАЯ КЛАВИАТУРА KEYCODE'/>
                                    <p>АНТИВАНДАЛЬНАЯ КОДОВАЯ КЛАВИАТУРА KEYCODE редназначена для управления автоматическими ограждающими конструкциями посредством прокси-карт и/или ПИН-кода.</p>
                                </div>
                                <div>
                                    <img src={fingerprint} alt='КОДОВАЯ КЛАВИАТУРА KEYFREM СО ВСТРОЕННЫМ СЧИТЫВАТЕЛЕМ ОТПЕЧАТКОВ ПАЛЬЦЕВ И КАРТ'/>
                                    <p>КОДОВАЯ КЛАВИАТУРА KEYFREM СО ВСТРОЕННЫМ СЧИТЫВАТЕЛЕМ ОТПЕЧАТКОВ ПАЛЬЦЕВ И КАРТ. KEYFREM — это автономный контроллер, оснащенный встроенным считывателем отпечатков пальцев, проксимити-карт и брелоков формата Em-Marine (арт. CARD EM, TRINKET EM) и клавиатурой для идентификации по коду. Клавиатура KEYFREM предназначена для управления одним устройством (шлагбаумом, электроприводом ворот или роллет, электрозамком и т. д.), а также может быть использована как внешняя клавишная панель или устройство считывания карт и отпечатков пальцев.</p>
                                </div>
                                <div>
                                    <img src={rolletTransmitter} alt='Пульт для дистанционного управления'/>
                                    <p>Пульт для дистанционного управления</p>
                                </div>
                                <div>
                                    <img src={transmitter4} alt='пульт'/>
                                    <p>Пульт Transmitter-4 для дистанционного управления 4 автоматическими устройствами</p>
                                </div>
                                <div>
                                    <img src={domophone} alt='КОМПЛЕКТ ВИДЕОДОМОФОНА DOMO7-PRO С ЭКРАНОМ 7'/>
                                    <p>Цветной видеодомофон Domo7-PRO используется для круглосуточного контроля доступа, вызовов, открывания дверей и ворот, обеспечения безопасности коттеджей, дач, домов, гостиниц, офисов и других объектов.</p>
                                </div>
                                <div>
                                    <img src={lock} alt='КОМПЛЕКТ ЗАМКА ЭЛЕКТРОМЕХАНИЧЕСКОГО DH-LOCK-KIT'/>
                                    <p>КОМПЛЕКТ ЗАМКА ЭЛЕКТРОМЕХАНИЧЕСКОГО DH-LOCK-KIT. Замок открывается ключом или пультом управления, обладает надежной защитой и высоким уровнем безопасности. Предназначен для калиток с электроприводами, системами контроля доступа и домофонами. Замок может устанавливаться в жилых, офисных и производственных зданиях, складах, гостиницах, общественных учреждениях и т. д.</p>
                                </div>
                                <div>
                                    <img src={lock2} alt='ЗАЩЕЛКА ЭЛЕКТРОМЕХАНИЧЕСКАЯ'/>
                                    <p>Электромеханическая защелка Estrike является ответной частью замка и устанавливается в дверную раму отдельно стоящих калиток. Устройство избавляет от необходимости установки кабелепрохода и переходников, а также сводит к минимуму требования к гибкости и прочности кабеля. Защелку можно использовать с любыми системами контроля доступа.</p>
                                </div>
                                <div>
                                    <img src={keySafe} alt='СЕЙФ КОДОВЫЙ ДЛЯ КЛЮЧЕЙ'/>
                                    <p>Устройство Safecode предназначено для хранения ключей расцепителей приводов для ворот, замков роллет, шлагбаумов и других автоматических систем.</p>
                                </div>
                            </div>
                        </div>
                        }
                        {activeTab === 4 && 
                            <div className='content'>
                                <h3>автоматика hoermann</h3>
                                <p>Наша компания реализует автоматику HOERMANN, поставляемую в комплектах готовых ворот.</p>
                                <p>C этой автоматикой Вы можете ознакомиться в соответствующем разделе:</p>
                                <NavLink className='hoermann' to='/garage'>
                                    <div><img src={garazh} alt='Гаражные ворота'/><p>гаражные ворота</p></div>
                                </NavLink>
                            </div>
                        }
                        <h5>ВЫБЕРИТЕ БРЕНД:</h5>
                        <div className='buttonWrapper'>
                            <button onClick={() => setActiveTab(1)}>alutech</button>
                            <button onClick={() => setActiveTab(2)}>nice</button>
                            <button onClick={() => setActiveTab(3)}>doorhan</button>
                            <button onClick={() => setActiveTab(4)}>hoermann</button>
                        </div>
                    </div>
                    

                </div>
            </div>
        </section>
    );
}